import consumer from "./consumer"

$(document).on('page:change', function() {
  if ($('form.employee-missing-collection-form').length) {
    const subObj = consumer.subscriptions;
    const subArray = subObj.subscriptions.filter(function(sub){
      let identifier = sub.identifier;
      if (!identifier.length) return false;

      return JSON.parse(sub.identifier)['channel'] == 'MissingCollectionChannel';
    });
    if (subArray.length) return;

    let $wrapperTable;
    let renderResultsWrapper = (newWrapperHtml) => {
      let $wrapper = $('#employeeMissingCollectionForm');
      if ($wrapper.length) {
        $wrapper.replaceWith(newWrapperHtml);
      }
      app.initialize($('#missingCollectionResults'));
      return $wrapper;
    }

    let setProgress = (data) => {
      let $wrapper = $('.progress-wrapper'),
          progressBar = $wrapper.find('.mdc-linear-progress')[0],
          total = Number.parseFloat($wrapper.attr('data-total')),
          progress = Number.parseFloat(data.num / total).toPrecision(2);

      $wrapper.find('.current').text(data.num);

      if (progressBar && progressBar.mdcLinearProgress) {
        progressBar.mdcLinearProgress.foundation.setProgress(progress);
      }
    }

    consumer.subscriptions.create("MissingCollectionChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('Connected to MissingCollectionChannel');
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log('Left MissingCollectionChannel');
      },

      received(data) {
        switch (data.status) {
          case 'initial':
            // Replace wrapper with new item
            renderResultsWrapper(data.wrapper_html);
            break;

          case 'in_progress':
            $wrapperTable = $('#missingCollectionResults');
            if (!$wrapperTable.length) {
              $wrapperTable = renderResultsWrapper(data.wrapper_html);
            }
            // Append item in wrapper
            $wrapperTable.find('tbody').append(data.html);

            break;

          case 'done':
            // Update current item
            $wrapperTable = $('#missingCollectionResults');
            let selector = '[data-id="ce' + data.id + '"]';

            setTimeout(function() {
              $wrapperTable.find(selector).replaceWith(data.html);
              setProgress(data);
              app.initialize($(selector));
            }, 300);

            break;
        }
      }
    })
  }
})
